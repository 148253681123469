import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import './Profile.css';
import Navbar from '../../components/navbar/Navbar';
import Verified from '../../components/verified/Verified';

const Profile = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [profile, setProfile] = useState({});
  const [generations, setGenerations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImg, setModalImg] = useState("");

  useEffect(() => {
    if (params.username) {
      setUsername(params.username);
    } else if (localStorage.getItem('account')) {
      setUsername(localStorage.getItem('account'));
    } else {
      navigate('/');
    }
  }, [params])

  useEffect(() => {
    if (username) {
      navigate(`/profile/inpaints/${username}`) // TEMP until we make actual profile page
      getprofile();
    }
  }, [username])

  const getprofile = () => {
    // fetch(`${process.env.REACT_APP_API}/api/getProfileWithGens/${username}`, {
    fetch(`${process.env.REACT_APP_API}/api/getProfile/${username}`, {

    })
    .then(response => response.json())
    .then(result => {
      setProfile(result.content);
      // setGenerations(result.content.generations);
    });
  }

  const openModal = (gen) => {
    setModalImg(gen.url);
    setIsModalOpen(true);
  }

  const closeModal = () => {
    if (isModalOpen) setIsModalOpen(false);
  }

  return (
    <div className="">
      <Navbar />

      <div className="profileWrapper">
        <div className="profile">
          {profile && profile.early ? <Verified early={true} /> : profile.verified ? <Verified /> : ""}
          <h1>{username}</h1>
        </div>
      </div>

      {username && username == localStorage.getItem('account') && <div className="profileWrapper">
        <div className="profile">
          <button onClick={() => navigate(`./inpaints/${username}`)}>Inpaints</button>
        </div>
      </div>}

      <div className="profileGenerationsWrapper">
        <div className="profileGenerations">
          {/* {generations && generations.map(gen => {
              return <div className="generation">
              <img src={gen.url} alt="gen" onClick={() => openModal(gen)}></img>
              <h4>{gen.title ? gen.title : (gen.prompt ? gen.prompt.length >= 50 ? (gen.prompt.substring(0, 49) + "...") : gen.prompt : "")}</h4>
              <p className="author">{gen.verified ? <Verified /> : ""} {gen.author ? gen.author : 'Guest'}</p>
              <p className="likes">{gen.likes} Likes</p>
            </div>;
          })} */}
        </div>
      </div>

      {isModalOpen && <div className="modal-wrapper" onClick={() => closeModal()}>
        <div className="modal">
          <img src={modalImg} alt="modalImg"></img>
        </div>
      </div>}
    </div>
  );
}

export default Profile;
