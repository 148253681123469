
import './Clone.css';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';
import CanvasDraw from 'react-canvas-draw';
import Dropzone from 'react-dropzone'
import { FaArrowCircleLeft, FaFolder, FaPlus } from 'react-icons/fa';
import { Button, Flex, Heading, Text, TextFieldInput } from '@radix-ui/themes';
import { toast } from 'react-toastify';

Array.prototype.random = function () {
  return this[Math.floor((Math.random()*this.length))];
}

const Clone = () => {
  let navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [imageBitmap, setImageBitmap] = useState(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState(1);
  const [showInpaintModal, setShowInpaintModal] = useState(false);
  const [loading, setLoading] = useState(false);
  
  let imgEl = document.createElement('img');
  imgEl.addEventListener('load', () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    let width = imgEl.width;
    let height = imgEl.height;

    if (width > 1024) {
      const val = width / 1024;
      width = width / val;
      height = height / val;
    }

    if (height > 1024) {
      const val = height / 1024;
      width = width / val;
      height = height / val;
    }

    canvas.width = width;
    canvas.height = height;

    setWidth(width);
    setHeight(height);

    const previewCanvas = document.getElementById("previewCanvas");
    const previewCanvasCtx = previewCanvas.getContext("2d");
    previewCanvas.width = width;
    previewCanvas.height = height;

    ctx.drawImage(imgEl, 0, 0, canvas.width, canvas.height);
    previewCanvasCtx.drawImage(imgEl, 0, 0, canvas.width, canvas.height);

    setImage(canvas.toDataURL());
    Promise.all([
      createImageBitmap(imgEl, { resizeWidth: width, resizeHeight: height })
    ]).then((sprites) => {
      setImageBitmap(sprites[0]);
    });
    
  });

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    fetch(`${process.env.REACT_APP_API}/api/getProfile/${localStorage.getItem('account')}`, {

    })
    .then(response => response.json())
    .then(result => {
      setBalance(result.content.balance);
    });
  }

  const handleModalClick = () => {
    setLoading(false);
  }

  const changeAmount = (e) => {
    setAmount(e.target.value);
  }

  const generateClones = async () => {
      if (localStorage.getItem('account') == null) {
        toast.info('You need to be logged in')
        navigate('/login');
        return;
      }
      setLoading(true);

      if (amount > balance) {
        toast.error("Not enough credits!")
        setLoading(false);
        return;
      }
  
      const formData = new FormData();
      let images = []
      const bar = new Promise((resolve, reject) => {
        for (let index = 0; index < amount; index++) {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
      
          canvas.width = width;
          canvas.height = height;
      
          console.log(imageBitmap)
          ctx.drawImage(imageBitmap, 0, 0, canvas.width, canvas.height);
      
          for(let i = 0; i < width; i++) {
            for(let j = 0; j < height; j++) {
                let num = Math.floor(Math.random()*255)
                ctx.fillStyle = "rgba(" + num + "," + num + "," + num + "," + 0.05 + ")";
                ctx.fillRect(i, j, 1, 1);
            }
          }
  
          canvas.toBlob((blob) => {
            images.push(blob)
            if (index >= (amount - 1)) resolve();
          });
        }
      });
      
      bar.then(() => {
        images.forEach((image, index) => formData.append(`Clone${index}`, image, `Clone${index}`))
        formData.append('author', localStorage.getItem('account'))
    
        fetch(`${process.env.REACT_APP_API}/api/generateNoise`, {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'same-origin',
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: formData
        })
        .then(response => {
          if (response.status == 200) return response.blob()
          else {
            response.json().then(data => toast.error(data.content));
          }
        })
        .then(result => {
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(result);
          downloadLink.download = "Clones";
  
          document.body.appendChild(downloadLink);
          downloadLink.click();
  
          setTimeout(() => {
            URL.revokeObjectURL(downloadLink.href);
            document.body.removeChild(downloadLink);
          }, 100);

          setLoading(false);
        })
        .catch(error => console.log('error', error));
      });
  }
    
  return (
      <div className="clone">
        <Navbar />

        <div className="cloneWrapper">
          {!image && <Dropzone onDrop={acceptedFiles => {
            const reader = new FileReader();
            reader.onloadend = () => {
              imgEl.src = reader.result;
            };
            reader.readAsDataURL(acceptedFiles[0]);
          }}>
            {({getRootProps, getInputProps}) => (
              <section className="cloneDropzone">
                <div {...getRootProps()} className='inpaintInnerDropzone'>
                  <FaFolder color="#0091FF" size="64px"/>
                  <input {...getInputProps()} />
                  <p>Drag a file or click here...</p>
                </div>
              </section>
            )}
          </Dropzone>}

          <div className="canvasWrapper">
            <canvas id="previewCanvas" height={0}></canvas>
          </div>

          {image && <div className="cloneButton">
            <button onClick={() => setShowInpaintModal(true)}>Clone</button>
          </div>}

          {loading && <div className="cloneModalWrapper" onClick={() => handleModalClick()}>
            <div className="cloneModal">
              <InfinitySpin 
                width='200'
                color="#00AFF0"
                />
              <h4>Generating...</h4>
            </div>
          </div>}

          {showInpaintModal && <div className="folderModalWrapper">
            <div className="folderModal">
              <Heading className="modalHeader">Create new clones</Heading>
              <div className="modalContent" style={{ width: "80%" }}>
                <Flex direction="column" gap="3" style={{ maxWidth: 400, marginBottom: '20px' }}>
                  <Text>Amount</Text>
                  <TextFieldInput type="number" placeholder="Model #1..." id="inpaintAmount" min="1" max="100" value={amount} style={{ width: 300 }} onChange={changeAmount}></TextFieldInput>
                </Flex>
              </div>
              <Flex className="modalFooter" gap="3">
                <Button onClick={() => setShowInpaintModal(false)} variant="outline" color="red"><FaArrowCircleLeft color="#bb0006d5" size="20px"/>Cancel</Button>
                <Button onClick={() => {setShowInpaintModal(false); generateClones()}}><FaPlus color="#FFF" size="16px"/>Generate Clones</Button>
              </Flex>
            </div>
          </div>}
        </div>
      </div>
  );
}

export default Clone;