import { useEffect, useState } from 'react';
import './Stripe.css';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import va from '@vercel/analytics'

const Stripe = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [stripeStatus, setStripeStatus] = useState("");

  useEffect(() => {
    if (localStorage.getItem('account') === null) navigate('/login')
    checkStripeStatus()
  }, [])

  const checkStripeStatus = () => {
    if (params.status === 'success') {
      va.track('Completed Purchase', { price: "$" + (localStorage.getItem('cart') * 10).toFixed(2)} );
      setStripeStatus('successfull')
      if (params.session) {
        handleCheckout()
      }
    } else if (params.status === 'canceled') {
      va.track('Canceled Purchase', { price: "$" + (localStorage.getItem('cart') * 10).toFixed(2)} );
      setStripeStatus('cancelled')
    }
  }

  const handleCheckout = () => {
    fetch(`${process.env.REACT_APP_API}/api/createPortalSession`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
          'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        session_id: params.session,
        username: localStorage.getItem('account'),
        cart: JSON.parse(localStorage.getItem('cart'))
      })
    })
    .then(response => response.json())
    .then(result => {
      window.location = result;
    })
    .catch(error => console.log('error', error));
  }

  const handleNavigate = () => {
    navigate('/');
  }

  return (
    <div className="stripe">
      <Navbar />

      <div className="stripeContent">
      
          {stripeStatus && <h1>Payment <span>{stripeStatus}</span>!</h1>}
          {stripeStatus && <h4>Please wait while we handle your payment.</h4>}
          <button className="button-info" onClick={handleNavigate.bind()}>
              <p>Homepage</p>
          </button>
      </div>
    </div>
  );
}

export default Stripe;