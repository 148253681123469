import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import './Login.css';
import { toast } from 'react-toastify';

const Login = () => {
    let navigate = useNavigate();

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
    
        fetch(`${process.env.REACT_APP_API}/api/login`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                username: event.target.username.value,
                password: event.target.password.value,
            })
        })
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    toast.success('Logged in!')
                    localStorage.setItem('account', data.content.username);
                    localStorage.setItem('verified', data.content.verified);
                    navigate('/');
                });
            } else {
                response.json().then(data => {
                    toast.error(data.content);
                });
            }
        })
    }

    return (
        <div className="login">
            <Navbar />

            <div className="login-inner">
                <form onSubmit={handleLoginSubmit}>
                    <div>
                        <h4>Log In</h4>
                    </div>

                    <div className="input-group">
                        <label>Username</label>
                        <input type="text" name="username" id="username"></input>
                    </div>

                    <div className="input-group">
                        <label>Password</label>
                        <input type="password" name="password" id="password"></input>
                    </div>

                    <button>Log In</button>
                    <Link to="/register">Register</Link>
                </form>
            </div>
        </div>
    );
}

export default Login;