import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import './BulkInpaints.css';
import Navbar from '../../components/navbar/Navbar';
import Verified from '../../components/verified/Verified';
import { toast } from 'react-toastify';

const BulkInpaints = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [inpaint, setInpaint] = useState(null);
  const [generations, setGenerations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImg, setModalImg] = useState("");

  useEffect(() => {
    if (localStorage.getItem('account')) {
      setUsername(localStorage.getItem('account'));
    } else {
      navigate('/');
    }
  }, [params])

  useEffect(() => {
    if (username) {
      getGenerations();
    }
  }, [username])

  const getGenerations = () => {
    fetch(`${process.env.REACT_APP_API}/api/getBulkInpaints/${params.id}`, {

    })
    .then(response => response.json())
    .then(result => {
      setInpaint(result.content.inpaint);
      setGenerations(result.content.inpaints);
    });
  }

  const downloadVideo = (url) => {
    toast.info("Download in progress...")
    fetch(`${process.env.REACT_APP_API}/api/downloadContent`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        url
      })
    })
    .then(response => {
      if (response.status == 200) return response.blob()
      else {
        response.json().then(data => toast.error(data.content));
      }
    })
    .then(result => {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(result);
      downloadLink.download = "Swapped Video";

      document.body.appendChild(downloadLink);
      downloadLink.click();

      setTimeout(() => {
        URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);
      }, 100);
    })
    .catch(error => console.log('error', error));
  }

  const downloadImage = (gen) => {
    toast.info("Download in progress...")
    fetch(`${process.env.REACT_APP_API}/api/downloadContent`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        url: gen.url,
        title: gen.title
      })
    })
    .then(response => {
      if (response.status == 200) return response.blob()
      else {
        response.json().then(data => toast.error(data.content));
      }
    })
    .then(result => {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(result);
      downloadLink.download = gen.title || "Content";

      document.body.appendChild(downloadLink);
      downloadLink.click();

      setTimeout(() => {
        URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);
      }, 100);
    })
    .catch(error => console.log('error', error));
  }

  const findAndDelete = (id) => {
    let temp = generations;
    const index = temp.findIndex(obj => obj._id === id);
    
    if (index !== -1) {
        // Remove the object at the found index
        temp.splice(index, 1);
        setGenerations(temp)
    } else {
        console.log('Object not found.');
    }
  }

  const deleteGen = (gen) => {
    toast.info("Deletion in progress...")
    findAndDelete(gen)
    fetch(`${process.env.REACT_APP_API}/api/deleteGen/${gen}`, {})
    .then(response => {
      if (response.status === 200) {
        toast.success("Deleted!")
      }
      else {
        response.json().then(data => toast.error(data.content));
      }
    })
    .then(result => {
    })
    .catch(error => console.log('error', error));
  }

  const openModal = (gen) => {
    setModalImg(gen.url);
    setIsModalOpen(true);
  }

  const closeModal = () => {
    if (isModalOpen) setIsModalOpen(false);
  }

  return (
    <div className="">
      <Navbar />

      {/* <div className="profileWrapper">
        <div className="profile">
          {profile && profile.early ? <Verified early={true} /> : profile.verified ? <Verified /> : ""}
          <h3>{username}</h3>
        </div>
      </div> */}

      <div className="profileGenerationsWrapper">
        <div className="profileGenerations">
          {inpaint && inpaint.length === 0 && <h1>No inpaints found.</h1>}
          {inpaint && generations && generations.length === 0 && inpaint.length > 0 && <h1>Please wait, we are processing your request.</h1>}
          {inpaint && inpaint.length > 0 && inpaint.map(paint => {
              if (paint.title && paint.title.includes('STATUS:') && paint.amount > 1) {
              return <div className="generation">
              <h4>{paint.title}</h4>
            </div>;
            } else return null
          })}
          {generations && generations.length > 0 && generations.map(gen => {
              if (gen.visible == undefined) {
              return <div className="generation">
                {gen.url.includes(".mp4") ? <video src={gen.url} alt="gen" className='fade-in' onClick={() => downloadVideo(gen.url)}></video> : <img src={gen.url} alt="gen" className='fade-in' onClick={() => downloadImage(gen)}></img>}
              
              <h4>{gen.title ? gen.title : "No Title"} <button onClick={() => deleteGen(gen._id)}>Delete</button></h4>
            </div>;
            } else return null
          })}
        </div>
      </div>

      {isModalOpen && <div className="modal-wrapper" onClick={() => closeModal()}>
        <div className="modal">
          <img src={modalImg} alt="modalImg" className='fade-in'></img>
        </div>
      </div>}
    </div>
  );
}

export default BulkInpaints;
