import { useEffect, useState } from 'react';
import './Tags.css';

const Tags = ({title, tags, selected, updateConfig}) => {
    const [tagsState, setTagsState] = useState({});

    useEffect(() => {
        tags.forEach(tag => {
            let temp = tagsState;
            temp[tag] = tag == selected ? true : false;

            setTagsState(tagsState);
        });
    }, [])

    const selectTag = (tag) => {
        updateConfig(title.toLowerCase().replace(/\s/g, ''), tag);
        tags.forEach(el => {
            let temp = tagsState;
            temp[el] = el == tag ? true : false;

            setTagsState(tagsState);
        });
    }

    return (
        <div className="tags-wrapper">
            <h1>{title}</h1>
            <div className="tags">
                {tags && tags.map(tag => {
                return <div className={tagsState[tag] ? 'tag tagSelected' : 'tag'} onClick={() => selectTag(tag)}>
                        <p>{tag}</p>
                    </div>;
                })}
            </div>
        </div>
    );
}

export default Tags;