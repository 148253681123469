import { useEffect, useState } from 'react';
import './Logging.css';
import Navbar from '../../components/navbar/Navbar';
import { FaFile } from 'react-icons/fa';
import moment from 'moment';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const footer = (tooltipItems) => {
  let sum = 0;

  tooltipItems.forEach(function(tooltipItem) {
    sum = tooltipItem.parsed.y * 0.05;
  });
  return '$: +' + sum.toFixed(2);
};


const options = {
  interaction: {
    intersect: false,
    mode: 'index',
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    tooltip: {
      callbacks: {
        footer: footer,
      }
    }
  },
};

const getLast30Days = () => {
  const today = new Date();
  const last30Days = [];

  for (let i = 29; i >= 0; i--) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    last30Days.push({ day, month, year });
  }

  return last30Days;
};

const Logging = () => {
  const [today, setToday] = useState(0);
  const [yesterday, setYesterday] = useState(0);
  const [week, setWeek] = useState(0);
  const [month, setMonth] = useState(0);
  const [logs, setLogs] = useState([]);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/getlogs`, {})
    .then(response => response.json())
    .then(result => {
      if (result.message == "success") {
        setLogs(result.content);
        calculateImageSwapsPerDay(result.content)
        setToday(countCreditsSpent(result.content, 'today'))
        setYesterday(countCreditsSpent(result.content, 'yesterday'))
        setWeek(countCreditsSpent(result.content, 'thisWeek'))
        setMonth(countCreditsSpent(result.content, 'thisMonth'))
      }
    });
  }, [])

  const calculateImageSwapsPerDay = (logs) => {
      const faceSwapLogs = logs.filter(log => log.category === "FaceSwap");
    
      const resultArray = Array.from({ length: 30 }, () => 0);
    
      const today = new Date();
      today.setUTCHours(0, 0, 0, 0);
    
      faceSwapLogs.forEach(log => {
        const logDate = new Date(log.createdOn);
    
        const daysAgo = Math.floor((today - logDate) / (24 * 60 * 60 * 1000));
    
        if (daysAgo >= 0 && daysAgo < 30) {
          const match = log.message.match(/Swapped (\d+) image/);
    
          if (match && match[1]) {
            const imagesSwapped = parseInt(match[1], 10);
            resultArray[daysAgo] += imagesSwapped;
          }
        }
      });
  
    setData({
      labels: getLast30Days().map(x => `${x.day}-${x.month}`),
      datasets: [
        {
          label: 'FaceSwaps',
          data: resultArray,
          borderColor: 'rgb(211, 48, 47)',
          backgroundColor: 'rgba(211, 48, 47)',
        },
      ],
    });
  }

  const getStartOfWeek = (date) => {
    const day = date.getDay() || 7; // Get the day of the week (Sunday is 0, Monday is 1, ..., Saturday is 6)
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - day + 1);
  }

  const countCreditsSpent = (logs, timeRange) => {
    // Get the current date
    const currentDate = new Date();

    // Define the start and end dates based on the timeRange
    let startDate, endDate;

    switch (timeRange) {
      case 'today':
        startDate = currentDate.toISOString().split('T')[0];
        endDate = startDate;
        break;
      case 'yesterday':
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
        startDate = yesterday.toISOString().split('T')[0];
        endDate = startDate;
        break;
      case 'thisWeek':
        startDate = getStartOfWeek(currentDate).toISOString().split('T')[0];
        endDate = currentDate.toISOString().split('T')[0];
        break;
      case 'thisMonth':
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        startDate = startDate.toISOString().split('T')[0];
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        endDate = endDate.toISOString().split('T')[0];
        break;
      default:
        throw new Error('Invalid timeRange');
    }

    // Filter logs based on the specified time range
    const filteredLogs = logs.filter(log => {
      const logDate = log.createdOn.split('T')[0];
      return logDate >= startDate && logDate <= endDate;
    });

    // Initialize credits counter
    let totalCreditsSpent = 0;

    // Iterate through the filtered logs and extract credits spent
    filteredLogs.forEach(log => {
      const regexResult = log.message.match(/(\d+) credits\./);
      if (regexResult) {
        // Extract the number of credits and add to the total
        const creditsSpent = parseInt(regexResult[1], 10);
        totalCreditsSpent += creditsSpent;
      }
    });

    return totalCreditsSpent;
  }

  return (
    <div className="">
      <Navbar />

      <div className="logging">
        <div className="log-wrapper">
          {data && <Bar options={options} data={data} />}
          <h4>Today: {today} (${(today * 0.05).toFixed(2)}) - Yesterday: {yesterday} (${(yesterday * 0.05).toFixed(2)}) - Week: {week} (${(week * 0.05).toFixed(2)}) - Month: {month} (${(month * 0.05).toFixed(2)})</h4>
          <div className="log">
            <p>Category</p>
            <p>Username</p>
            <p>Date</p>
            <p>Message</p>
            <p>Detail</p>
          </div>
          <hr></hr>

          {logs && logs.map(log => <div className="log">
            <p>{log.category}</p>
            <p>{log.author?.username || ''}</p>
            <p>{moment(log.createdOn).format('YYYY-MM-DD HH:mm:ss')}</p>
            <p>{log.message}</p>
            <p>{log.detail ? <FaFile /> : ''}</p>
          </div>)}
        </div>
      </div>
    </div>
  );
}

export default Logging;
