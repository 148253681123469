import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import './ProfileBulkInpaints.css';
import Navbar from '../../components/navbar/Navbar';
import Verified from '../../components/verified/Verified';
import { FaArrowCircleLeft, FaBackward, FaFolder, FaStop, FaStopCircle } from 'react-icons/fa';
import { Button, Flex, Heading, Text, TextFieldInput } from '@radix-ui/themes';
import { InfinitySpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';

const ProfileBulkInpaints = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [profile, setProfile] = useState({});
  const [folders, setFolders] = useState(null);
  const [showFolderModal, setShowFolderModal] = useState(false);

  useEffect(() => {
    if (params.username) {
      setUsername(params.username);
    } else if (localStorage.getItem('account')) {
      setUsername(localStorage.getItem('account'));
    } else {
      navigate('/');
    }
  }, [params])

  useEffect(() => {
    if (username) {
      getProfile();
    }
  }, [username])

  const getProfile = () => {
    fetch(`${process.env.REACT_APP_API}/api/getProfile/${username}`, {

    })
    .then(response => response.json())
    .then(result => {
      setProfile(result.content);
      setFolders(result.content.folders);
    });
  }

  const createFolder = () => {
    const folderName = document.getElementById("folderName").value;
    if (!folderName.length > 0) {
      toast.error('Folder name required');
      return;
    }

    fetch(`${process.env.REACT_APP_API}/api/makeFolder`, {
      method: 'POST',
      
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
          author: username,
          title: folderName,
      })
    })
    .then(response => {
      getProfile();
      setShowFolderModal(false);
    });
  }

  return (
    <div className="">
      <Navbar />

      <div className="profileWrapper">
        <div className="profile">
          <Verified early={true} />
          <h1>{username}</h1>
        </div>
      </div>

      <div className="profileGenerationsWrapper">
        <div className="">
          <Flex gap="3" direction="row-reverse">
            <Button onClick={() => setShowFolderModal(true)}><FaFolder color="#FFF" size="20px"/> Create Folder</Button>
          </Flex>
          <ul className="folders">
            {!folders && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '130px' }}>
              <InfinitySpin 
                width='200'
                color="#00AFF0"
              />
            </div>}
            {folders && folders.map(folder => {
              return <li className="folder" onClick={() => navigate(`/profile/bulkInpaints/${folder._id}`)}>
                <FaFolder color="#0091FF" size="20px"/>
                <h4>{folder.title}</h4>
                <h4 style={{ textAlign: 'right', width: '100%' }}>{folder.amount}</h4>
                {/* <hr></hr> */}
              </li>;
            })}
          </ul>
        </div>
      </div>

      {showFolderModal && <div className="folderModalWrapper">
          <div className="folderModal">
            <Heading className="modalHeader">Create new folder</Heading>
            <Flex direction="column" gap="3" style={{ maxWidth: 400 }} className="modalContent">
              <Text>Folder Name</Text>
              <TextFieldInput type="text" placeholder="Model #1..." id="folderName" style={{ width: 300 }}></TextFieldInput>
            </Flex>
            <Flex className="modalFooter" gap="3">
              <Button onClick={() => setShowFolderModal(false)} variant="outline" color="red"><FaArrowCircleLeft color="#bb0006d5" size="20px"/> Cancel</Button>
              <Button onClick={() => createFolder()}><FaFolder color="#FFF" size="20px"/> Create Folder</Button>
            </Flex>
          </div>
        </div>}
    </div>
  );
}

export default ProfileBulkInpaints;
