
import { useNavigate } from 'react-router-dom';
import './NoMatch.css';

const NoMatch = () => {
    let navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/');
    }

    return (
        <div className="dm">
            <div className="dm-content">
                <h1><span>404</span> Not Found</h1>
                <button className="button-info" onClick={handleNavigate.bind()}>
                    <p>Homepage</p>
                </button>
            </div>
        </div>
    );
}

export default NoMatch;