import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import './Secret.css';
import Navbar from '../../components/navbar/Navbar';
import { InfinitySpin } from 'react-loader-spinner';

const Secret = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (params.secret) {
      fetch(`${process.env.REACT_APP_API}/api/patreonSecret`, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
            secret: params.secret,
            username: localStorage.getItem('account'),
        })
      })
      .then(response => response.json())
      .then(result => {
        if (result.message == "success") {
          localStorage.setItem('verified', true);
        }
        setStatus(result.message);
        setLoading(false);
        setTimeout(() => navigate('/'), 5000);
      });
    } else {
      navigate('/');
    }
  }, [params])

  return (
    <div className="">
      <Navbar />

      <div className="secret">
        {loading && <InfinitySpin 
          width='200'
          color="#0091FF"
        />}
        {!loading && <div>
          <h1>{status == 'success' ? 'Patreon Linked!' : 'Something went wrong...'}</h1>
        </div>}
      </div>
    </div>
  );
}

export default Secret;
