
import './Inpaint.css';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';
import CanvasDraw from 'react-canvas-draw';
import Dropzone from 'react-dropzone'
import { FaArrowCircleLeft, FaFolder, FaPlus } from 'react-icons/fa';
import { Button, Flex, Heading, Text, TextFieldInput } from '@radix-ui/themes';
import { toast } from 'react-toastify';

Array.prototype.random = function () {
  return this[Math.floor((Math.random()*this.length))];
}

const Inpaint = () => {
  let navigate = useNavigate();

  const [folders, setFolders] = useState([]);
  const [folder, setFolder] = useState("");
  const [prompt, setPrompt] = useState("");
  const [inpaintName, setInpaintName] = useState("");
  const [image, setImage] = useState(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [balance, setBalance] = useState(0);
  const [originalDataUrl, setOriginalDataUrl] = useState(null);
  const [brushSize, setBrushSize] = useState(20);
  const [amount, setAmount] = useState(1);
  const [result, setResult] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [showInpaintModal, setShowInpaintModal] = useState(false);
  const [showBulkOptions, setShowBulkOptions] = useState(false);
  const [showAddImages, setShowAddImages] = useState(false);
  const [extraImages, setExtraImages] = useState([]);
  const [loading, setLoading] = useState(false);
  
  let imgEl = document.createElement('img');
  imgEl.addEventListener('load', () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    let width = imgEl.width;
    let height = imgEl.height;

    if (width > 1024) {
      const val = width / 1024;
      width = width / val;
      height = height / val;
    }

    if (height > 1024) {
      const val = height / 1024;
      width = width / val;
      height = height / val;
    }

    canvas.width = width;
    canvas.height = height;

    setWidth(width);
    setHeight(height);

    ctx.drawImage(imgEl, 0, 0, canvas.width, canvas.height);

    setOriginalDataUrl(canvas.toDataURL());
    setImage(canvas.toDataURL());
  });

  useEffect(() => {
    if (loading) {
      fetch(`${process.env.REACT_APP_API}/api/getProfile/${localStorage.getItem('account')}`, {

      })
      .then(response => response.json())
      .then(result => {
        localStorage.setItem('verified', result.content.verified);
        if (result.content.verified) {
          checkLoading(5);
        } else {
          checkLoading(5);
          // checkLoading(120);
        }
      });
    }
  }, [loading]);

  useEffect(() => {
    getProfile();
    toast.error("We are experiencing technical difficulties.")
    toast.error("Please don't do any faceswapping or inpainting while we work on fixing it.")
    toast.error("All users who spent credits during this period will be reimbursed!")
  }, []);

  const getProfile = () => {
    fetch(`${process.env.REACT_APP_API}/api/getProfile/${localStorage.getItem('account')}`, {

    })
    .then(response => response.json())
    .then(result => {
      setBalance(result.content.balance);
      setFolders(result.content.folders);
      setFolder(result.content.folders[0]._id);
    });
  }

  const inpaintImage = (count) => {
    if (localStorage.getItem('account') == null) {
      toast.info('You need to be logged in')
      navigate('/login');
      return;
    }
    setLoading(true);

    if ((count > 1 || extraImages.length > 0) && !folder.length > 0) {
      toast.info('You need to select a folder')
      setLoading(false);
      setShowResult(false);
      return;
    }

    if (extraImages.length > 0) {
      if ((count * extraImages.length) > balance) {
        toast.error("Not enough credits!")
        setLoading(false);
        setShowResult(false);
        return;
      }
    } else if (count > balance) {
      toast.error("Not enough credits!")
      setLoading(false);
      setShowResult(false);
      return;
    }

    const formData = new FormData();
    extraImages.forEach(image => formData.append(image.name, image, image.name))
    formData.append('original', originalDataUrl)
    formData.append('mask', saveableCanvas.getDataURL())
    formData.append('prompt', prompt)
    formData.append('author', localStorage.getItem('account'))
    formData.append('amount', count)
    formData.append('folder', folder)
    formData.append('inpaintName', inpaintName)
    formData.append('width', Math.floor(width))
    formData.append('height', Math.floor(height))

    fetch(`${process.env.REACT_APP_API}/api/${(count > 1 || extraImages.length > 0) ? 'bulkInpaintImage' : 'inpaintImage'}`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: formData
    })
    .then(response => response.json())
    .then(result => {
      getProfile();
      if (amount > 1 || extraImages.length > 0) {
        toast.success('Bulk inpaint processing')
        setLoading(false);
        setShowResult(false);
        setResult({});
        setExtraImages([]);
        // navigate(`/profile/bulkInpaints/${result.content}`)
      } else {
        setResult(result);
      }
    })
    .catch(error => console.log('error', error));
  }

  const checkLoading = (time) => {
    if (time > 0) {
      setTimeout(() => checkLoading(time - 1), 1000);
    } else {
      setShowResult(true);
    }
  }

  const handleModalClick = () => {
    if (showResult && result.message) {
      setLoading(false);
      setShowResult(false);
      setResult({});
    }
  }

  const handleInpaintName = (e) => {
    setInpaintName(e.target.value);
  }

  const handleFolderChange = (e) => {
    setFolder(e.target.value);
  }

  const handleChange = (e) => {
    setPrompt(e.target.value);
  }

  const changeAmount = (e) => {
    setAmount(e.target.value);
  }

  const changeBrushSize = (e) => {
    setBrushSize(e.target.value);
  }

  const zoomLimits = { min: 0.33, max: 1 }
  let saveableCanvas;

  return (
      <div className="inpaint">
        <Navbar />

        <div className="inpaintWrapper">
          {!image && <Dropzone onDrop={acceptedFiles => {
            const reader = new FileReader();
            reader.onloadend = () => {
              imgEl.src = reader.result;
            };
            reader.readAsDataURL(acceptedFiles[0]);
          }}>
            {({getRootProps, getInputProps}) => (
              <section className="inpaintDropzone">
                <div {...getRootProps()} className='inpaintInnerDropzone'>
                  <FaFolder color="#0091FF" size="64px"/>
                  <input {...getInputProps()} />
                  <p>Drag a file or click here...</p>
                </div>
              </section>
            )}
          </Dropzone>}

          {image && <input type="range" id="inpaintBrushSize" min="5" max="50" value={brushSize} onChange={changeBrushSize}></input>}
          <div className="canvasWrapper">
            {image && <CanvasDraw 
                  ref={canvasDraw => (saveableCanvas = canvasDraw)}
                  loadTimeOffset={0}
                  lazyRadius={0}
                  brushRadius={brushSize}
                  brushColor="#fff"
                  catenaryColor="#fff"
                  backgroundColor="#27272B"
                  canvasWidth={width}
                  canvasHeight={height}
                  hideGrid={true}
                  imgSrc={image}
                  mouseZoomFactor={0.01}
                  zoomExtents={zoomLimits} 
            />}
          </div>

          {image && <div className="inpaintButton">
            <button onClick={() => setShowInpaintModal(true)}>Inpaint</button>
          </div>}

          {loading && <div className="inpaintModalWrapper" onClick={() => handleModalClick()}>
            <div className="inpaintModal">
              {(!showResult || !result.message) && <InfinitySpin 
                width='200'
                color="#00AFF0"
                />}
              {(!showResult || !result.message) && <h4>Generating...</h4>}
              {showResult && result.message && result.message === "success" && <img src={result.content} alt="gen"></img>}
              {showResult && result.message && result.message === "success" && <h4>Succes!</h4>}
              {showResult && result.message && result.message === "processing" && <h4>Check Later!</h4>}
            </div>
          </div>}

          {showInpaintModal && <div className="folderModalWrapper">
            <div className="folderModal">
              <Heading className="modalHeader">Create new inpaint</Heading>
              <div className="modalContent" style={{ width: "80%" }}>
                {!showBulkOptions && <Flex direction="column" gap="3" style={{ maxWidth: 400, marginBottom: '20px' }}>
                  <Text>Prompt</Text>
                  <TextFieldInput type="text" placeholder="Model #1..." value={prompt} id="prompt" style={{ width: 300 }} onChange={handleChange}></TextFieldInput>
                </Flex>}
                {showBulkOptions && !showAddImages && <Flex direction="column" gap="3" style={{ maxWidth: 400 }}>
                  <Text>Parent Folder</Text>
                  <select id="folderName" onChange={handleFolderChange} value={folder} style={{ marginBottom: '20px' }}>
                    {folders && folders.map(folder => {
                      return <option value={folder._id}>
                        {folder.title}
                      </option>;
                    })}
                  </select>
                </Flex>}
                {showBulkOptions && !showAddImages && <Flex direction="column" gap="3" style={{ maxWidth: 400, marginBottom: '20px' }}>
                  <Text>Inpaint Name</Text>
                  <TextFieldInput type="text" placeholder="Model #1..." id="inpaintName" value={inpaintName} style={{ width: 300 }} onChange={handleInpaintName}></TextFieldInput>
                </Flex>}
                {showBulkOptions && !showAddImages && <Flex direction="column" gap="3" style={{ maxWidth: 400, marginBottom: '20px' }}>
                  <Text>Amount</Text>
                  <TextFieldInput type="number" placeholder="Model #1..." id="inpaintAmount" min="1" max="100" value={amount} style={{ width: 300 }} onChange={changeAmount}></TextFieldInput>
                </Flex>}
                {showAddImages && <Flex direction="column" gap="3" style={{ maxWidth: 400, marginBottom: '20px', height: "100%" }}>
                  <Text>{extraImages ? extraImages.length : 0} Images Added</Text>
                  <Dropzone onDrop={async acceptedFiles => {
                    acceptedFiles.forEach((file) => {
                      const reader = new FileReader()

                      reader.onabort = () => console.log('file reading was aborted');
                      reader.onerror = () => console.log('file reading has failed');
                      reader.onload = () => {
                        let temp = [...extraImages, file]
                        setExtraImages(temp);
                      }
                      reader.readAsArrayBuffer(file);
                    });
                  }}>
                    {({getRootProps, getInputProps}) => (
                      <section className="inpaintDropzone">
                        <div {...getRootProps()} className='inpaintInnerDropzone'>
                          <FaFolder color="#0091FF" size="64px"/>
                          <input {...getInputProps()} />
                          <p>Drag files or click here...</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Flex>}
              </div>
              <Flex className="modalFooter" gap="3">
                <Button onClick={() => {setShowAddImages(false); showBulkOptions ? setShowBulkOptions(false) : setShowInpaintModal(false)}} variant="outline" color="red"><FaArrowCircleLeft color="#bb0006d5" size="20px"/> {showBulkOptions ? 'Back' : 'Cancel'}</Button>
                {!showBulkOptions && <Button onClick={() => setShowBulkOptions(true)}><FaFolder color="#FFF" size="20px"/> Bulk</Button>}
                {showBulkOptions && !showAddImages && <Button onClick={() => setShowAddImages(true)}><FaFolder color="#FFF" size="20px"/> Add Images</Button>}
                {/* <Button disabled={(showBulkOptions && !folder)} onClick={() => {setShowInpaintModal(false); inpaintImage(showBulkOptions ? amount : 1)}}><FaPlus color="#FFF" size="16px"/> {showBulkOptions ? 'Inpaint' : 'Single'}</Button> */}
                <Button disabled={true} onClick={() => {setShowInpaintModal(false); inpaintImage(showBulkOptions ? amount : 1)}}><FaPlus color="#FFF" size="16px"/> {showBulkOptions ? 'Inpaint' : 'Single'}</Button>
              </Flex>
            </div>
          </div>}
        </div>
      </div>
  );
}

export default Inpaint;