
import './Generate.css';
import Navbar from '../../components/navbar/Navbar';
import Tags from '../../components/tags/Tags';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';

Array.prototype.random = function () {
  return this[Math.floor((Math.random()*this.length))];
}

const Generate = () => {
  let navigate = useNavigate();

  const age = ["20 year old", "20-30 years old", "30-40 years old", "40-50 years old", "50-60 years old", "60-70 years old"];
  const body  = ["busty", "beautiful", "skinny", "thick", "chubby", "fat", "short", "tall", "pregnant"];
  const ethinicity = ["african", "arabic", "asian", "#27272B", "brazilian", "british", "chinese", "czech", "dutch", "egyptian", "ethiopian", "filipina", "french", "german", "greek", "hungarian", "indian", "indonesian", "irish", "italian", "japanese", "jewish", "korean", "latina", "malaysian", "middle eastern", "mongolian", "native american", "nigerian", "nilotic", "persian", "polynesian", "portuguese", "russian", "scandinavian", "spanish", "swedish", "thai", "turkish", "vietnamese", "black"];
  const haircolor = ["#27272B", "blonde", "brown", "ginger", "black"];
  const hairstyle = ["straight", "bobcut", "pigtails", "hair bun", "pixie", "ponytail", "messy", "bangs", "braided", "slicked"];
  const eyes = ["blue", "green", "brown", "#27272B"];
  const style = ["full body", "close up"];
  const boobs = ["small boobs", "normal boobs", "big boobs", "huge boobs", "massive boobs"];
  const setting = ["bathroom", "beach", "bedroom", "car", "gym", "hot tub", "kitchen", "lake", "mountains", "office", "pool", "sauna", "shower", "street", "strip club", "underwater"];
  const clothing = ["nude", "bathrobe", "bdsm", "bikini", "blouse", "cheerleader outfit", "crop top", "nurse outfit", "dominatrix", "flight attendant", "high heels", "jeans", "jumpsuit", "latex", "leather", "lingerie", "maid outfit", "micro bikini", "mini skirt", "panties", "push-up bra", "short shorts", "sports bra", "sundress", "underwear", "yoga pants"];

  let default_config = {
    age: "20 year old",
    body: body.random(),
    ethinicity: ethinicity.random(),
    haircolor: haircolor.random(),
    hairstyle: hairstyle.random(),
    eyes: eyes.random(),
    style: style.random(),
    boobs: boobs.random(),
    setting: setting.random(),
    clothing: clothing.random(),
  }

  const [config, setConfig] = useState(default_config);
  const [currentPrompt, setCurrentPrompt] = useState();
  const [result, setResult] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentPrompt(configToPrompt(config));
  }, [])
  
  useEffect(() => {
    if (loading) {
      fetch(`${process.env.REACT_APP_API}/api/getProfile/${localStorage.getItem('account')}`, {

      })
      .then(response => response.json())
      .then(result => {
        localStorage.setItem('verified', result.content.verified);
        if (result.content.verified) {
          checkLoading(5);
        } else {
          // checkLoading(120);
          checkLoading(5);
        }
      });
    }
  }, [loading]);

  const generateImage = () => {
    if (localStorage.getItem('account') == null) navigate('/login');
    setLoading(true);


    fetch(`${process.env.REACT_APP_API}/api/generateImage`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
          'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
          title: configToTitle(config),
          prompt: configToPrompt(config),
          author: localStorage.getItem('account'),
      })
    })
    .then(response => response.json())
    .then(result => {
      setResult(result);
    })
    .catch(error => console.log('error', error));
  }

  const checkLoading = (time) => {
    if (time > 0) {
      setTimeout(() => checkLoading(time - 1), 1000);
    } else {
      setShowResult(true);
    }
  }

  const updateConfig = (key, value) => {
    let temp = config;
    temp[key] = value;
    setConfig(temp)
    setCurrentPrompt(configToPrompt(temp))
  }

  const configToTitle = (config) => {
    return `${config.age} ${config.ethinicity} girl, ${config.clothing === 'nude' ? 'nude': ('wearing ' + config.clothing)} in ${config.setting}`;
  }

  const configToPrompt = (config) => {
    return `a sexy NSFW ${config.style} photo of a naked ${config.age} (${config.body}) ${config.ethinicity} girl, ${config.haircolor} ${config.hairstyle} hair, ${config.eyes} eyes, (${config.boobs}), background ${config.setting}, (((${config.clothing === 'nude' ? 'nude': ('sexy ' + config.clothing)})))`;
  }

  const handleModalClick = () => {
    if (showResult && result.message) {
      setLoading(false);
      setShowResult(false);
      setResult({});
    }
  }
    
  return (
      <div className="generate">
        <Navbar />
        <div className="tagsWrapper">
          <div className="tagsRow">
            <Tags title="Body" tags={body} selected={default_config.body} updateConfig={updateConfig} />
            <Tags title="Age" tags={age} selected={default_config.age} updateConfig={updateConfig} />
            <Tags title="Hair Color" tags={haircolor} selected={default_config.haircolor} updateConfig={updateConfig} />
            <Tags title="Hair Style" tags={hairstyle} selected={default_config.hairstyle} updateConfig={updateConfig} />
            <Tags title="Clothing" tags={clothing} selected={default_config.clothing} updateConfig={updateConfig} />
          </div>
          <div className="tagsRow">
            <Tags title="Eyes" tags={eyes} selected={default_config.eyes} updateConfig={updateConfig} />
            <Tags title="Style" tags={style} selected={default_config.style} updateConfig={updateConfig} />
            <Tags title="Boobs" tags={boobs} selected={default_config.boobs} updateConfig={updateConfig} />
            <Tags title="Setting" tags={setting} selected={default_config.setting} updateConfig={updateConfig} />
            <Tags title="Ethinicity" tags={ethinicity} selected={default_config.ethinicity} updateConfig={updateConfig} />
          </div>
        </div>
        {/* <input onChange={handleChange}></input> */}
        <div className="tagsGenerate">
          <button onClick={() => generateImage()}>Generate</button>
        </div>

        {loading && <div className="generateModalWrapper" onClick={() => handleModalClick()}>
          <div className="generateModal">
            {(!showResult || !result.message) && <InfinitySpin 
              width='200'
              color="#0091FF"
              />}
            {(!showResult || !result.message) && <h4>Generating...</h4>}
            {showResult && result.message && result.message === "success" && <img src={result.content} alt="gen"></img>}
            {showResult && result.message && result.message === "success" && <h4>Succes!</h4>}
            {showResult && result.message && result.message === "processing" && <h4>Check Later!</h4>}
          </div>
        </div>}
      </div>
  );
}

export default Generate;