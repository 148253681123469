
import './FaceSwap.css';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';
import Dropzone from 'react-dropzone'
import { FaArrowCircleLeft, FaFolder, FaPlus } from 'react-icons/fa';
import { Button, Flex, Heading, Text, TextFieldInput } from '@radix-ui/themes';
import { toast } from 'react-toastify';

Array.prototype.random = function () {
  return this[Math.floor((Math.random()*this.length))];
}

const FaceSwap = () => {
  let navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [imageBitmap, setImageBitmap] = useState(null);
  const [imageBitmap2, setImageBitmap2] = useState(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [width2, setWidth2] = useState(0);
  const [height2, setHeight2] = useState(0);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState(1);
  const [showInpaintModal, setShowInpaintModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState([]);
  const [folder, setFolder] = useState("");
  
  let imgEl = document.createElement('img');
  let imgEl2 = document.createElement('img');

  imgEl.addEventListener('load', () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    let width = imgEl.width;
    let height = imgEl.height;

    if (width > 1024) {
      const val = width / 1024;
      width = width / val;
      height = height / val;
    }

    if (height > 1024) {
      const val = height / 1024;
      width = width / val;
      height = height / val;
    }

    canvas.width = width;
    canvas.height = height;

    setWidth(width);
    setHeight(height);

    const previewCanvas = document.getElementById("previewCanvas");
    const previewCanvasCtx = previewCanvas.getContext("2d");
    previewCanvas.width = width;
    previewCanvas.height = height;

    ctx.drawImage(imgEl, 0, 0, canvas.width, canvas.height);
    previewCanvasCtx.drawImage(imgEl, 0, 0, canvas.width, canvas.height);

    setImage(canvas.toDataURL());
    Promise.all([
      createImageBitmap(imgEl, { resizeWidth: width, resizeHeight: height })
    ]).then((sprites) => {
      setImageBitmap(sprites[0]);
    });
  });

  imgEl2.addEventListener('load', () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    let width = imgEl2.width;
    let height = imgEl2.height;


    if (width > 1024) {
      const val = width / 1024;
      width = width / val;
      height = height / val;
    }

    if (height > 1024) {
      const val = height / 1024;
      width = width / val;
      height = height / val;
    }

    canvas.width = width;
    canvas.height = height;

    setWidth2(width);
    setHeight2(height);

    const previewCanvas = document.getElementById("previewCanvas2");
    const previewCanvasCtx = previewCanvas.getContext("2d");
    previewCanvas.width = width;
    previewCanvas.height = height;

    ctx.drawImage(imgEl2, 0, 0, canvas.width, canvas.height);
    previewCanvasCtx.drawImage(imgEl2, 0, 0, canvas.width, canvas.height);

    setImage2(canvas.toDataURL());
    Promise.all([
      createImageBitmap(imgEl2, { resizeWidth: width, resizeHeight: height })
    ]).then((sprites) => {
      setImageBitmap2(sprites[0]);
    });
    
  });


  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    fetch(`${process.env.REACT_APP_API}/api/getProfile/${localStorage.getItem('account')}`, {

    })
    .then(response => response.json())
    .then(result => {
      setBalance(result.content.balance);
      setFolders(result.content.folders);
      setFolder(result.content.folders[0]._id);
    });
  }

  const handleModalClick = () => {
    setLoading(false);
  }

  const changeAmount = (e) => {
    setAmount(e.target.value);
  }

  const swap = async () => {
      if (localStorage.getItem('account') == null) {
        toast.info('You need to be logged in')
        navigate('/login');
        return;
      }
      setLoading(true);

  
      const formData = new FormData();
      let images = []
      const bar = new Promise((resolve, reject) => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
      
          canvas.width = width;
          canvas.height = height;
      
          ctx.drawImage(imageBitmap, 0, 0, canvas.width, canvas.height);
          console.log('bitmap1', imageBitmap)
          
          canvas.toBlob((blob) => {
            images.push(blob)
            console.log('pushin1', blob)
          });
          
          canvas.width = width2;
          canvas.height = height2;
          
          ctx.drawImage(imageBitmap2, 0, 0, canvas.width, canvas.height);
          console.log('bitmap2', imageBitmap2)
          
          canvas.toBlob((blob) => {
            images.push(blob)
            console.log('pushin2', blob)
            resolve();
          });
      });
      
      bar.then(() => {
        console.log(images)
        images.forEach((image, index) => formData.append(`Swap${index}`, image, `Swap${index}`))
        formData.append('author', localStorage.getItem('account'))
        formData.append('folder', folder)
    
        fetch(`${process.env.REACT_APP_API}/api/faceSwap`, {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'same-origin',
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: formData
        })
        .then(response => {
          if (response.status == 200) return response.blob()
          else {
            response.json().then(data => toast.error(data.content));
          }
          setLoading(false);
        })
        .then(result => {
          setLoading(false);
          toast.success("Face Swap in Progress")
        })
        .catch(error => {
          console.log('error', error)
          setLoading(false);
        });
      });
  }

  const handleFolderChange = (e) => {
    setFolder(e.target.value);
  }
    
  return (
      <div className="faceSwap">
        <Navbar />

        <Flex direction="column" gap="3" align="center" style={{ minWidth: 400, marginTop: '20px' }}>
          <Text>{folders.length == 0 ? 'Create a folder on your profile page first' : 'Parent Folder*'}</Text>
          <select id="folderName" onChange={handleFolderChange} value={folder} style={{ marginBottom: '20px' }}>
            {folders && folders.map(folder => {
              return <option value={folder._id}>
                {folder.title}
              </option>;
            })}
          </select>
        </Flex>

        <div className="faceSwapWrapper">
          <div className='faceSwapWrapperInner'>
            <h4>Face Image</h4>
            {!image && <Dropzone onDrop={acceptedFiles => {
              const reader = new FileReader();
              reader.onloadend = () => {
                imgEl.src = reader.result;
              };
              reader.readAsDataURL(acceptedFiles[0]);
            }}>
              {({getRootProps, getInputProps}) => (
                <section className="faceSwapDropzone">
                  <div {...getRootProps()} className='inpaintInnerDropzone'>
                    <FaFolder color="#0091FF" size="64px"/>
                    <input {...getInputProps()} />
                    <p>Drag a file or click here...</p>
                  </div>
                </section>
              )}
            </Dropzone>}

            <div className="canvasWrapper" style={{ width: image ? 'auto' : '0px', maxWidth: "45%" }}>
              <canvas id="previewCanvas" height={0}></canvas>
            </div>
          </div>

          <div className='faceSwapWrapperInner'>
            <h4>Target Image</h4>

            {!image2 && <Dropzone onDrop={acceptedFiles => {
              const reader = new FileReader();
              reader.onloadend = () => {
                imgEl2.src = reader.result;
              };
              reader.readAsDataURL(acceptedFiles[0]);
            }}>
              {({getRootProps, getInputProps}) => (
                <section className="faceSwapDropzone">
                  <div {...getRootProps()} className='inpaintInnerDropzone'>
                    <FaFolder color="#0091FF" size="64px"/>
                    <input {...getInputProps()} />
                    <p>Drag a file or click here...</p>
                  </div>
                </section>
              )}
            </Dropzone>}

            <div className="canvasWrapper" style={{ width: image2 ? 'auto' : '0px', maxWidth: "45%"  }}>
              <canvas id="previewCanvas2" height={0}></canvas>
            </div>
          </div>

          {image && image2 && folder && <div className="faceSwapButton">
            <button onClick={() => swap()} disabled={loading}>{loading ? 'Loading...' : 'Swap'}</button>
          </div>}
        </div>
      </div>
  );
}

export default FaceSwap;