
import { useNavigate } from 'react-router-dom';
import './Logo.css';

const Logo = () => {
    let navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/');
    }

    return (
        <h1 onClick={() => handleNavigate()} className="logo">Agen<span>See</span></h1>
    );
}

export default Logo;