import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import './Register.css';
import { toast } from 'react-toastify';

const Register = () => {
    let navigate = useNavigate();

    const handleRegisterSubmit = (event) => {
        event.preventDefault();
        if (event.target.email.value === "" || event.target.username.value === "" || event.target.password.value === "") {
            toast.error('Missing fields!')
            return;
        }

        fetch('https://jsonip.com/')
        .then(function(response) {
            return response.json();
        })
        .then(function(response) {
            console.log(response.ip);
        });

        // return;

        if (localStorage.getItem("registered") == "true") {
            toast.error('Max accounts reached!')
            return;
        }
    
        fetch(`${process.env.REACT_APP_API}/api/register`, {
            method: 'POST',
            
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                email: event.target.email.value,
                username: event.target.username.value,
                password: event.target.password.value,
            })
        })
        .then(response => {
        if (response.status === 200) {
            toast.success('Registered!')
            localStorage.setItem("registered", "true")
            navigate('/login');
        } else if (response.status === 409) {
            toast.error('Account already exists')
        } else if (response.status === 500) {
            toast.error('Server error')
        }
        });
    }

    return (
        <div className="register">
            <Navbar />

            <div className="register-inner">
                <form onSubmit={handleRegisterSubmit}>
                    <div>
                        <h4>Register</h4>
                    </div>

                    <div className="input-group">
                        <label>Username</label>
                        <input type="text" name="username"></input>
                    </div>

                    <div className="input-group">
                        <label>Email</label>
                        <input type="email" name="email"></input>
                    </div>

                    <div className="input-group">
                        <label>Password</label>
                        <input type="password" name="password"></input>
                    </div>

                    <button>Register</button>
                    <Link to="/login">Log In</Link>
                </form>
            </div>
        </div>
    );
}

export default Register;