
import './SuperResolution.css';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';

Array.prototype.random = function () {
  return this[Math.floor((Math.random()*this.length))];
}

const SuperResolution = () => {
  let navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [originalDataUrl, setOriginalDataUrl] = useState(null);
  const [result, setResult] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState(false);
  
  let imgEl = document.createElement('img');
  imgEl.addEventListener('load', () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = 512;
    canvas.height = 512;

    ctx.drawImage(imgEl, 0, 0, canvas.width, canvas.height);
    setOriginalDataUrl(canvas.toDataURL());
    setImage(canvas.toDataURL());
  });

  useEffect(() => {
    if (loading) {
      fetch(`${process.env.REACT_APP_API}/api/getProfile/${localStorage.getItem('account')}`, {

      })
      .then(response => response.json())
      .then(result => {
        localStorage.setItem('verified', result.content.verified);
        if (result.content.verified) {
          checkLoading(5);
        } else {
          checkLoading(5);
          // checkLoading(120);
        }
      });
    }
  }, [loading]);

  const superResolution = () => {
    if (localStorage.getItem('account') == null) navigate('/login');
    setLoading(true);


    fetch(`${process.env.REACT_APP_API}/api/superResolution`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
          'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        original: originalDataUrl,
        author: localStorage.getItem('account'),
      })
    })
    .then(response => response.json())
    .then(result => {
      setResult(result);
    })
    .catch(error => console.log('error', error));
  }

  const checkLoading = (time) => {
    if (time > 0) {
      setTimeout(() => checkLoading(time - 1), 1000);
    } else {
      setShowResult(true);
    }
  }

  const handleModalClick = () => {
    if (showResult && result.message) {
      setLoading(false);
      setShowResult(false);
      setResult({});
    }
  }
    
  return (
      <div className="inpaint">
        <Navbar />

        <div className="inpaintWrapper">
          <input
            type="file"
            name="myImage"
            onChange={(event) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                  imgEl.src = reader.result;
              };
              reader.readAsDataURL(event.target.files[0]);
              
            }}
          />

          <div className="canvasWrapper">
            {image && <img src={image}></img>}
          </div>

          <div className="inpaintButton">
            <button onClick={() => superResolution()}>Super Resolution</button>
          </div>

          {loading && <div className="inpaintModalWrapper" onClick={() => handleModalClick()}>
            <div className="inpaintModal">
              {(!showResult || !result.message) && <InfinitySpin 
                width='200'
                color="#0091FF"
                />}
              {(!showResult || !result.message) && <h4>Generating...</h4>}
              {showResult && result.message && result.message === "success" && <img src={result.content} alt="gen"></img>}
              {showResult && result.message && result.message === "success" && <h4>Succes!</h4>}
              {showResult && result.message && result.message === "processing" && <h4>Check Later!</h4>}
            </div>
          </div>}
        </div>
      </div>
  );
}

export default SuperResolution;