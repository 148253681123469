import React from 'react';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import '@radix-ui/themes/styles.css';

import Home from './pages/home/Home';
import NoMatch from './pages/nomatch/NoMatch';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import Generate from './pages/generate/Generate';
import Profile from './pages/profile/Profile';
import Secret from './pages/secret/Secret';
import Inpaint from './pages/inpaint/Inpaint';
import SuperResolution from './pages/superresolution/SuperResolution';
import ProfileBulkInpaints from './pages/profilebulkinpaints/ProfileBulkInpaints';
import BulkInpaints from './pages/bulkinpaints/BulkInpaints';
import Stripe from './pages/stripe/Stripe';
import { Theme } from '@radix-ui/themes';
import { Analytics } from '@vercel/analytics/react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Refill from './pages/refill/Refill';
import Clone from './pages/clone/Clone';
import FaceSwap from './pages/faceswap/FaceSwap';
import FaceSwapVideo from './pages/faceswapvideo/FaceSwapVideo';
import Logging from './pages/logging/Logging';

const rootElement = document.getElementById("root");
render(
  <Theme accentColor="blue">
    <ToastContainer />
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Clone />} />
        <Route path='swap' element={<FaceSwap />} />
        <Route path='swapvideo' element={<FaceSwapVideo />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="generate" element={<Generate />} />
        <Route path="magic" element={<Inpaint />} />
        <Route path="superresolution" element={<SuperResolution />} />
        <Route path="admin/logs" element={<Logging />} />
        <Route path="profile" element={<Profile />} />
        <Route exact path="profile/refill" element={<Refill />} />
        <Route path="profile/inpaints/:username" element={<ProfileBulkInpaints />} />
        <Route path="profile/bulkinpaints/:id" element={<BulkInpaints />} />
        <Route path="profile/:username" element={<Profile />} />
        <Route path="patreon/:secret" element={<Secret />} />
        <Route path="/stripe/:status" element={<Stripe />} />
        <Route path="/stripe/:status/:session" element={<Stripe />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
    <Analytics />
  </Theme>,
  rootElement
);
