import './Refill.css';
import Navbar from '../../components/navbar/Navbar';
import { Flex, Heading, Text, TextFieldInput } from '@radix-ui/themes';
import { useState } from 'react';
import va from '@vercel/analytics'

const Refill = () => {
  const [amount, setAmount] = useState(1);

  const handleSubmit = () => {
    localStorage.setItem('cart', amount);
    va.track('Start Purchase', { price: "$" + (amount * 10).toFixed(2)} );

    fetch(`${process.env.REACT_APP_API}/api/createCheckoutSession`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
          'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        coin_id: "prod_OUfqzbU53SiMN1", // prod_OUfqzbU53SiMN1 = 250 coins fee
        // coin_id: "prod_OUfqekMZXafXtk", // prod_OUfqekMZXafXtk = test 250 coins fee
        coin_amount: amount,
      })
    })
    .then(response => response.json())
    .then(result => {
      window.location = result;
    })
    .catch(error => console.log('error', error));
  }

  const changeAmount = (e) => {
    let temp = e.target.value;
    if (temp < 1) temp = 1;
    if (temp > 50) temp = 50;
    setAmount(Math.round(temp));
  }

  return (
    <div className="refill">
      <Navbar />

      <div className="refillContent">
        <Heading style={{ marginBottom: '20px', fontSize: '32px' }}>Top up your balace.</Heading>


        <Flex direction="column" gap="3" style={{ maxWidth: 400, marginBottom: '20px' }}>
          <TextFieldInput type="number" placeholder="1" id="inpaintAmount" min="1" max="50" value={amount} style={{ width: 200 }} onChange={changeAmount}></TextFieldInput>
        </Flex>
        
        <h4 style={{ marginBottom: '40px' }}>200 coins for $10</h4>

        <h3 style={{ marginBottom: '0px' }}>${(amount * 10).toFixed(2)} | {(amount * 200).toFixed(2)} coins</h3>

        <div className="inpaintButton">
          <button onClick={() => handleSubmit()}>Refill</button>
        </div>
      </div>
    </div>
  );
}

export default Refill;