
import './FaceSwapVideo.css';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';
import Dropzone from 'react-dropzone'
import { FaArrowCircleLeft, FaFolder, FaPlus } from 'react-icons/fa';
import { Button, Flex, Heading, Text, TextFieldInput } from '@radix-ui/themes';
import { toast } from 'react-toastify';

Array.prototype.random = function () {
  return this[Math.floor((Math.random()*this.length))];
}

const FaceSwapVideo = () => {
  let navigate = useNavigate();

  const [creditPrice, setCreditPrice] = useState(null);
  const [videoLength, setVideoLength] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [image2, setImage2] = useState(null);
  const [imageBitmap2, setImageBitmap2] = useState(null);
  const [width2, setWidth2] = useState(0);
  const [height2, setHeight2] = useState(0);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState(1);
  const [showInpaintModal, setShowInpaintModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState([]);
  const [folder, setFolder] = useState("");
  
  let imgEl2 = document.createElement('img');

  imgEl2.addEventListener('load', () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    let width = imgEl2.width;
    let height = imgEl2.height;


    if (width > 1024) {
      const val = width / 1024;
      width = width / val;
      height = height / val;
    }

    if (height > 1024) {
      const val = height / 1024;
      width = width / val;
      height = height / val;
    }

    canvas.width = width;
    canvas.height = height;

    setWidth2(width);
    setHeight2(height);

    const previewCanvas = document.getElementById("previewCanvas2");
    const previewCanvasCtx = previewCanvas.getContext("2d");
    previewCanvas.width = width;
    previewCanvas.height = height;

    ctx.drawImage(imgEl2, 0, 0, canvas.width, canvas.height);
    previewCanvasCtx.drawImage(imgEl2, 0, 0, canvas.width, canvas.height);

    setImage2(canvas.toDataURL());
    Promise.all([
      createImageBitmap(imgEl2, { resizeWidth: width, resizeHeight: height })
    ]).then((sprites) => {
      setImageBitmap2(sprites[0]);
    });
    
  });


  useEffect(() => {
    getProfile();
    toast.info("Video swapping is in beta and experiences a handful of issues")
    toast.error("We are experiencing technical difficulties.")
    toast.error("Please don't do any faceswapping or inpainting while we work on fixing it.")
    toast.error("All users who spent credits during this period will be reimbursed!")
  }, []);

  const getProfile = () => {
    fetch(`${process.env.REACT_APP_API}/api/getProfile/${localStorage.getItem('account')}`, {

    })
    .then(response => response.json())
    .then(result => {
      setBalance(result.content.balance);
      setFolders(result.content.folders);
      setFolder(result.content.folders[0]._id);
    });
  }

  const handleModalClick = () => {
    setLoading(false);
  }

  const changeAmount = (e) => {
    setAmount(e.target.value);
  }

  const calculateCreditPrice = (durationInSeconds, creditPerSecond) => {
    // Round up the duration to the nearest 0.2 seconds
    const roundedDuration = Math.ceil(durationInSeconds / 0.2) * 0.2;
  
    // Calculate the credit price
    const creditPrice = roundedDuration * creditPerSecond;
  
    return Math.floor(creditPrice);
  }

  const swap = async () => {
      if (localStorage.getItem('account') == null) {
        toast.info('You need to be logged in')
        navigate('/login');
        return;
      }
      setLoading(true);

  
      const formData = new FormData();
      let images = []
      const bar = new Promise((resolve, reject) => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
      
          canvas.width = width2;
          canvas.height = height2;
      
          ctx.drawImage(imageBitmap2, 0, 0, canvas.width, canvas.height);
  
          canvas.toBlob((blob) => {
            images.push(video)
            images.push(blob)
            resolve();
          });
      });
      
      bar.then(() => {
        images.forEach((image, index) => formData.append(`SwapVideo${index}`, image, `SwapVideo${index}`))
        formData.append('author', localStorage.getItem('account'))
        formData.append('folder', folder)
        formData.append('length', videoLength)
    
        fetch(`${process.env.REACT_APP_API}/api/faceSwapVideo`, {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'same-origin',
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: formData
        })
        .then(response => {
          if (response.status == 200) return response.blob()
          else {
            response.json().then(data => toast.error(data.content));
          }
        })
        .then(result => {
          toast.success("Video Face Swap in Progress")
        })
        .catch(error => console.log('error', error));
      });
  }

  const handleFolderChange = (e) => {
    setFolder(e.target.value);
  }
    
  return (
      <div className="faceSwap">
        <Navbar />

        <Flex direction="column" gap="3" align="center" style={{ minWidth: 400, marginTop: '20px' }}>
          <Text>{folders.length == 0 ? 'Create a folder on your profile page first' : 'Parent Folder*'}</Text>
          <select id="folderName" onChange={handleFolderChange} value={folder} style={{ marginBottom: '20px' }}>
            {folders && folders.map(folder => {
              return <option value={folder._id}>
                {folder.title}
              </option>;
            })}
          </select>
        </Flex>

        <div className="faceSwapWrapper">
          <div className='faceSwapWrapperInner'>
            <h4>Video</h4>
            {!video && <Dropzone onDrop={acceptedFiles => {
              const reader = new FileReader();
              reader.onload = function(e) {
                let buffer = e.target.result;
                let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });
                console.log(videoBlob)
                let url = window.URL.createObjectURL(videoBlob);
                setVideo(videoBlob)
                setVideoUrl(url)

                const video = document.createElement('video');

                video.addEventListener('loadedmetadata', function() {
                  const videoLengthInSeconds = video.duration;
                  setVideoLength(videoLengthInSeconds);
                  setCreditPrice(calculateCreditPrice(videoLengthInSeconds, 5));
                });

                video.addEventListener('error', function(e) {
                  console.error('Error loading video:', e.message);
                });

                video.src = url;
                video.preload = 'metadata'; // Load only metadata to get the duration
              }
              reader.readAsArrayBuffer(acceptedFiles[0]);
            }}>
              {({getRootProps, getInputProps}) => (
                <section className="faceSwapDropzone">
                  <div {...getRootProps()} className='inpaintInnerDropzone'>
                    <FaFolder color="#0091FF" size="64px"/>
                    <input {...getInputProps()} />
                    <p>Drag a file or click here...</p>
                  </div>
                </section>
              )}
            </Dropzone>}

            {videoUrl && <div className="canvasWrapper">
              <video src={videoUrl}></video>
            </div>}
          </div>

          <div className='faceSwapWrapperInner'>
            <h4>Image</h4>

            {!image2 && <Dropzone onDrop={acceptedFiles => {
              const reader = new FileReader();
              reader.onloadend = () => {
                imgEl2.src = reader.result;
              };
              reader.readAsDataURL(acceptedFiles[0]);
            }}>
              {({getRootProps, getInputProps}) => (
                <section className="faceSwapDropzone">
                  <div {...getRootProps()} className='inpaintInnerDropzone'>
                    <FaFolder color="#0091FF" size="64px"/>
                    <input {...getInputProps()} />
                    <p>Drag a file or click here...</p>
                  </div>
                </section>
              )}
            </Dropzone>}

            <div className="canvasWrapper" style={{ width: image2 ? 'auto' : '0px', maxWidth: "45%" }}>
              <canvas id="previewCanvas2" height={0}></canvas>
            </div>
          </div>

          {video && image2 && folder && <div className="faceSwapButton">
            {videoLength < 30 && <small>This swap will cost {creditPrice} credits</small>}
            {videoLength > 30 && <small>Max video length is 30 seconds.</small>}
            {/* <button onClick={() => swap()} disabled={videoLength > 30}>Swap</button> */}
            <button onClick={() => swap()} disabled={true}>Swap</button>
          </div>}
        </div>
      </div>
  );
}

export default FaceSwapVideo;