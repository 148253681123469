
import './ProfileDropDown.css';
import { FaCoins, FaDiscord, FaFile, FaPatreon, FaTelegram, FaUserAlt, FaWallet } from 'react-icons/fa';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProfileDropDown = () => {
    let navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [balance, setBalance] = useState(0);
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        let user = localStorage.getItem('account');
        if (user != null) {
            setUsername(user);

            fetch(`${process.env.REACT_APP_API}/api/getProfile/${user}`, {

            })
            .then(response => response.json())
            .then(result => {
              localStorage.setItem('verified', result.content.verified);
              setVerified(result.content.verified);
              setBalance(result.content.balance);
            });
        }
    }, [])

    const handleSelect = () => {
        window.location = 'https://www.patreon.com/oauth2/authorize?response_type=code&client_id=FDdbghDCc2MyJ0AHu39C4rY7ne6pMMkty6yMjn2jIvTSJ23ZqAV4YWpUikr-_buO&redirect_uri=https://dream-api.online/api/redirect';
    }

    const handleDiscord = () => {
        window.open('https://discord.gg/proporngen', '_blank');
    }

    const handleTelegram = () => {
        window.open('https://t.me/agensee', '_blank');
    }

    const logout = () => {
        localStorage.removeItem('account');
        localStorage.removeItem('verified');
        setUsername("");
    }

    return (
        <div className="profileDropDown">
            <button className="IconButton coinButton" aria-label="Customise options" onClick={() => navigate('/profile/refill')}>
                <p>{balance || 0}</p>
                <FaCoins color="#0091FF" size="20px"/>
            </button>
            <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <button className="IconButton" aria-label="Customise options">
                    <FaUserAlt color="#0091FF" size="20px"/>
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                <DropdownMenu.Label className="DropdownMenuLabel">{username}</DropdownMenu.Label>
                {!username && <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => navigate('/login')}>
                    Log in
                </DropdownMenu.Item>}
                {!username && <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => navigate('/register')}>
                    Register
                </DropdownMenu.Item>}
                {username && username.length > 0 && <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => navigate('/profile')}>
                    Profile
                </DropdownMenu.Item>}
                <DropdownMenu.Separator className="DropdownMenuSeparator" />
                {username && username.length > 0 && <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => navigate('/')}>
                    Clone
                </DropdownMenu.Item>}
                {username && username.length > 0 && <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => navigate('/magic')}>
                    Magic Brush
                </DropdownMenu.Item>}
                {username && username.length > 0 && <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => navigate('/swap')}>
                    Face Swap
                </DropdownMenu.Item>}
                {username && username.length > 0 && <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => navigate('/swapvideo')}>
                    Video Face Swap (BETA)
                </DropdownMenu.Item>}
                <DropdownMenu.Separator className="DropdownMenuSeparator" />
                {username && username === "DavidK" && <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => navigate('/admin/logs')}>
                    <FaFile size="14" id="telegram" />
                    Logs
                </DropdownMenu.Item>}
                {username && username === "DavidK" && <DropdownMenu.Separator className="DropdownMenuSeparator" />}
                {/* {username && username.length > 0 && !verified && <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => handleSelect()}>
                    <FaPatreon color="#0091FF" size="14" id="patreon" />
                    Link Patreon
                </DropdownMenu.Item>} */}
                {/* <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => handleDiscord()}>
                    <FaDiscord size="14" id="discord" />
                    Join Discord
                </DropdownMenu.Item> */}


                {username && username.length > 0 && <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => navigate('/profile/refill')}>
                    <FaCoins size="14" id="telegram" />
                    Buy Coins
                </DropdownMenu.Item>}
                {username && username.length > 0 && <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => window.location = "https://billing.stripe.com/p/login/14k2aJbl57EQaNW8ww"}>
                    <FaWallet size="14" id="telegram" />
                    Billing
                </DropdownMenu.Item>}
                {username && username.length > 0 && <DropdownMenu.Separator className="DropdownMenuSeparator" />}
                <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => handleTelegram()}>
                    <FaTelegram size="14" id="telegram" />
                    Join Telegram
                </DropdownMenu.Item>
                {username && username.length > 0 && <DropdownMenu.Item className="DropdownMenuItem" onSelect={() => logout()}>
                    Log out
                </DropdownMenu.Item>}

                <DropdownMenu.Arrow className="DropdownMenuArrow" />
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </div>
    );
}

export default ProfileDropDown;