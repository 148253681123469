
import './Navbar.css';
import Logo from '../logo/Logo';
import { Link } from 'react-router-dom';
import ProfileDropDown from '../ProfileDropDown/ProfileDropDown';

const Navbar = () => {
    return (
        <nav>
            <div className="nav-left">
                <Logo />
                {/* <input type="text" id="search" placeholder='Search...'></input> */}
                {/* <Link to="/generate">Generate</Link> */}
            </div>

            <ProfileDropDown />
      </nav>
    );
}

{/* <a href="https://www.patreon.com/oauth2/authorize?response_type=code&client_id=psjIBI2Z0q28DkbWfzPthnPEyDtL15EYXCxPyMx1B3tIRZ2gicYbZDf06QvVf9fT&redirect_uri=http://localhost:3030/api/redirect" className="btn patreon">
    <img src="https://www.vectorlogo.zone/logos/patreon/patreon-tile.svg" alt="patreon" className='patreon'></img>
    <p>Patreon</p>
</a> */}

export default Navbar;