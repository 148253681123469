import { useEffect, useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import Verified from '../../components/verified/Verified';
import { toast } from 'react-toastify';

const Home = () => {
  let navigate = useNavigate();
  const [generations, setGenerations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImg, setModalImg] = useState("");

  useEffect(() => {
    getGenerations();
    toast.error("We are experiencing technical difficulties.")
    toast.error("Please don't do any faceswapping or inpainting while we work on fixing it.")
    toast.error("All users who spent credits during this period will be reimbursed!")
  }, [])

  const getGenerations = () => {
    fetch(`${process.env.REACT_APP_API}/api/getGenerations`, {

    })
    .then(response => response.json())
    .then(result => {
      setGenerations(result.content);
    });
  }

  const openModal = (gen) => {
    setModalImg(gen.url);
    setIsModalOpen(true);
  }

  const closeModal = () => {
    if (isModalOpen) setIsModalOpen(false);
  }
  
  return (
    <div className="App" onClick={() => closeModal()}>
      <Navbar />

      <div className="sub-nav">
        {/* <h4 onClick={() => navigate('/superresolution')}>Super Resolution</h4> */}
        <h4 onClick={() => navigate('/inpaint')}>Inpaint</h4>
        <h4 className="disabled">Categories</h4>
        <h4 className="disabled">Tags</h4>
        <h4 className="disabled">Random</h4>
        <h4 onClick={() => navigate('/profile')}>Profile</h4>
      </div>

      {/* <div className="generations-wrapper">
        {generations && generations.map(gen => {
              return <div className="generation">
              <img src={gen.url} alt="gen" onClick={() => openModal(gen)}></img>
              <h4>{gen.title ? gen.title : (gen.prompt ? gen.prompt.length >= 50 ? (gen.prompt.substring(0, 49) + "...") : gen.prompt : "")}</h4>
              <p className="author" onClick={() => navigate(`/profile/${gen.author}`)}><Verified early={true} /> {gen.author ? gen.author : 'Guest'}</p>
              <p className="likes">{gen.likes} Likes</p>
            </div>;
        })}
      </div>

      {isModalOpen && <div className="modal-wrapper" onClick={() => closeModal()}>
        <div className="modal">
          <img src={modalImg} alt="modalImg"></img>
        </div>
      </div>} */}

    </div>
  );
}

export default Home;
